import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import withRouter from "../../hooks/withRouter"

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";
import companyLogo from "../../assets/images/companyLogo.png";
import techLabLogo from "../../assets/images/techLabLogo.png";

const Sidebar = props => {

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box d-md-block">
          <div className="logo logo-light">
            <span className="justify-content-center">
                <img src={companyLogo} alt="" width="auto" height="50" />
              <span className="chat-conversation"></span>
            </span>
          </div>
        </div>
        <div data-simplebar className="custom-h-90">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background">
        </div>
        <div className="navbar-brand-box-techlab d-inline-block">
           <span className="logo-sm">
             <img src={techLabLogo} alt="" className="logo-sm" height="30" />
            </span>
        </div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
